<template>
  <div class="c-getting-started">
    <div class="c-getting-started__title">
      <h2 class="c-getting-started__title-subtitle">Start using</h2>
      <h1 class="c-getting-started__title-text">Sparrow’s Options Trading Platform here</h1>
    </div>
    <div class="c-getting-started__description">
      <p class="c-getting-started__description-text">Begin your cryptocurrency and options trading activities on Sparrow platform with TradePROTECT, TradeBOOST and the ConvertNOW Facility. Simply register your account with Sparrow in three simple steps to get started.</p>
    </div>
    <!-- <div class="c-getting-started__body">
      <div class="c-getting-started__body-form">
          <input class="c-getting-started__body-form-input" v-model="name" placeholder="Full Name"/>
          <input class="c-getting-started__body-form-input" v-model="company" placeholder="Company"/>
          <input class="c-getting-started__body-form-input" v-model="contactNumber" placeholder="Contact Number"/>
          <input class="c-getting-started__body-form-input" v-model="email" placeholder="Email"/>
          <div class="c-getting-started__body-form-checkbox-wrapper" @click="clickTerm()">
            <div class="c-getting-started__body-form-checkbox" :class="{'c-getting-started__body-form-checkbox_blue': termChecked}">
              <div class="c-getting-started__body-form-checkbox-checkmark" v-if="termChecked"></div>
            </div>
            <label class="c-getting-started__body-form-checkbox-text">I have read and agreed to terms in the <span class="c-getting-started__body-form-checkbox-text_underline" @click="openLink('/legal/privacy-policies')">Privacy Policy</span></label>
          </div>
          <div class="c-getting-started__body-form-checkbox-wrapper" @click="clickInformation()">
            <div class="c-getting-started__body-form-checkbox" :class="{'c-getting-started__body-form-checkbox_blue': informationChecked}">
              <div class="c-getting-started__body-form-checkbox-checkmark" v-if="informationChecked"></div>
            </div>
            <label class="c-getting-started__body-form-checkbox-text">Please send me information and news updates from Sparrow</label>
          </div>
          <div class="c-getting-started__body-form-button">
            <button class="c-getting-started__body-form-button-submit" :class="{'c-getting-started__body-form-button-submit_disable': !termChecked || !checkFormValue}" @click="submitData">Submit</button>
          </div>
        </div>
    </div> -->
  </div>
</template>

<script>
  export default {
    name: 'GettingStartedConsumer',
    data() {
      return {
        name: '',
        company: '',
        contactNumber: '',
        email: '',
        termChecked: false,
        informationChecked: false,
      }
    },
    mounted() {

    },
    methods: {
      clickTerm() {
        this.termChecked = !this.termChecked
      },
      clickInformation() {
        this.informationChecked = !this.informationChecked
      },
      submitData() {
        if(this.termChecked && this.checkFormValue) {
          this.$eventHub.$emit('show-alert', true, {title: 'Thank you for your query and interest in Sparrow`s digital asset wealth management solutions.', body: 'Our team will be in touch with you shortly.'}, true)
        }
      }
    },
    computed: {
      checkFormValue() {
        if(this.name != '' && this.company != '' && this.contactNumber != '' && this.email!= '') {
          return true
        } else {
          return false
        }
      }
    }
  }
</script>
